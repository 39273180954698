import * as React from "react";
import { Alert, AlertColor, Box, Card, Grid, Snackbar } from "@mui/material";
import { useGetCclEventsMutation } from "../../services/cclTokenedSessionApi";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import { useGetAssetsBySessionKeyQuery } from "../../services/cclTokenedAssetsApi";
import { useGetCclParticipantsByEventKeyQuery } from "../../services/cclTokenedEnterpriseParticipantApi";
import { EventDocument } from "../../services/types/search.service.types";
import ScoringReportDownloadsDataGrid from "./scoringReportDownloadsDataGrid";
import ComponentLoader from "../../components/common/componentLoader";
import { FileDownloadService } from "../../services/fileDownloadService/fileDownloadService";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import { AccessEventSessionDetails } from "../../services/types/accessEventTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";

export interface ScoringReportDownloadsProps {}

type SnackBarProps = {
    message: string;
    severity: AlertColor | undefined;
};

export const ScoringReportDownloads: React.FC<ScoringReportDownloadsProps> = (props) => {
    const [foundEsKey, setFoundEsKey] = React.useState<number | null>(null);
    const [foundProgramCode, setFoundProgramCode] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarParams, setSnackbarParams] = React.useState<SnackBarProps>({
        message: "",
        severity: undefined,
    });
    const [
        searchForEvent,
        { isLoading: searching, isSuccess: searchSuccess, isError: searchError },
    ] = useGetCclEventsMutation();
    const {
        data: allAssets,
        isLoading: loadingAssets,
        isSuccess: assetsSuccess,
        isError: assetsError,
    } = useGetAssetsBySessionKeyQuery(foundEsKey?.toString() || "", {
        skip: foundEsKey == null,
    });
    const {
        data: allParticipants,
        isLoading: loadingParticipants,
        isSuccess: participantsSuccess,
        isError: participantsError,
    } = useGetCclParticipantsByEventKeyQuery(foundEsKey?.toString() || "", {
        skip: foundEsKey == null,
    });

    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const ShowSnackbarElement = (props: SnackBarProps) => {
        setShowSnackbar(true);
        setSnackbarParams(props);
    };

    React.useEffect(() => {
        if (searchSuccess && participantsSuccess && assetsSuccess) {
            if (allAssets?.length === 0 || allParticipants?.length === 0) {
                ShowSnackbarElement({
                    message: "No assessments found for this session.",
                    severity: "warning",
                });
            }
        }
    }, [searchSuccess, participantsSuccess, assetsSuccess, allAssets, allParticipants]);

    React.useEffect(() => {
        if (searchError || assetsError || participantsError) {
            ShowSnackbarElement({
                message: "Error retrieving session or related assessments.",
                severity: "error",
            });
        }
    }, [searchError, assetsError, participantsError]);

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        switch (eventName) {
            case "download":
                let filename: string = "";
                let aikeys = selectedIds.map((i) => +i);
                if (aikeys.length > 30) {
                    ShowSnackbarElement({
                        message: "Maximum of 30 files may be downloaded at one time.",
                        severity: "error",
                    });
                } else if (aikeys.length > 1) {
                    filename =
                        foundProgramCode !== ""
                            ? `${foundProgramCode}_ScoredAssessments.zip`
                            : "ScoredAssessments.zip";
                } else if (aikeys.length === 1 && allAssets != null && allAssets.length >= 1) {
                    let ast = allAssets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        let fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    return; // nothing selected
                }
                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: true })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: foundProgramCode ?? "",
                        };
                        logEvent("ParticipantFilesDownloaded", evtData);
                        ShowSnackbarElement({
                            message: "File download(s) complete.",
                            severity: "success",
                        });
                    })
                    .catch((error: { message: string }) => {
                        const msg = error.message ?? "Unknown Error";
                        ShowSnackbarElement({
                            message: `File download(s) error: ${msg}.`,
                            severity: "error",
                        });
                    });
                break;
        }
    };

    const getSessionByKeyword = (keyword: string) => {
        searchForEvent({ keyword: keyword })
            .unwrap()
            .then((results: EventDocument[]) => {
                let matches = results.filter(
                    (e) => e.eventnumber.toString() === keyword || e.projectCode === keyword
                );
                if (matches?.length > 0) {
                    setFoundEsKey(+matches[0].eventnumber);
                    setFoundProgramCode(matches[0].projectCode);
                } else {
                    setFoundEsKey(null);
                    setFoundProgramCode("");
                    setShowSnackbar(true);
                    setSnackbarParams({ message: "Session was not found.", severity: "warning" });
                }
            })
            .catch((error) => {
                console.log("Error searching for events: " + error);
            });
    };

    return (
        <Box width={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CclTextSearchBar
                        initialSearchTerm={""}
                        searchLabel="Enter Session ID or Code"
                        executeSearch={getSessionByKeyword}
                    />
                </Box>
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: 1,
                        width: 1,
                        padding: 2,
                        paddingTop: 0,
                    }}
                >
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            position: "relative",
                        }}
                    >
                        {showSnackbar ? (
                            <Snackbar
                                open={true}
                                anchorOrigin={{
                                    horizontal: "center",
                                    vertical: "bottom",
                                }}
                                autoHideDuration={5000}
                                onClose={() => setShowSnackbar(false)}
                                sx={{ position: "absolute" }}
                            >
                                <Alert severity={snackbarParams.severity} sx={{ width: "100%" }}>
                                    {snackbarParams.message}
                                </Alert>
                            </Snackbar>
                        ) : null}
                        <div style={{ flexGrow: 1 }}>
                            {searching || loadingAssets || loadingParticipants ? (
                                // search, or loading in progress
                                <ComponentLoader msg={"Searching for event..."} />
                            ) : searchSuccess &&
                              participantsSuccess &&
                              assetsSuccess &&
                              allAssets?.length > 0 &&
                              allParticipants?.length > 0 ? (
                                <React.Fragment>
                                    <ScoringReportDownloadsDataGrid
                                        participants={
                                            allParticipants?.filter(
                                                (p) => p.registrationStatus === "Confirmed"
                                            ) ?? []
                                        }
                                        assets={
                                            allAssets?.filter((a) => a.fileType === "Assessment") ??
                                            []
                                        }
                                        handleEvent={onGridToolbarButtonClick}
                                    />
                                </React.Fragment>
                            ) : (
                                <CclSearchDefault
                                    line1="Start searching for your Session"
                                    line2="Enter PR Code or ID"
                                />
                            )}
                        </div>
                    </Box>
                </Grid>
            </Card>
        </Box>
    );
};

export default ScoringReportDownloads;
