import * as React from "react";
import { Drawer, Stack, Typography } from "@mui/material";

export interface CclDrawerProps {
    title: string;
    open: boolean;
    onClose: () => void;
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

export const CclDrawer: React.FC<CclDrawerProps> = (props) => {
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "block",
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: "35%",
                    top: "60px",
                },
            }}
        >
            <Stack direction="column" spacing={3} sx={{ padding: "6px" }}>
                <Typography
                    variant={"h6"}
                    align={"left"}
                    sx={{
                        padding: "16px 24px",
                        borderBottom: "1px solid gray",
                    }}
                >
                    {props.title}
                </Typography>
                {props.children}
            </Stack>
        </Drawer>
    );
};

export default CclDrawer;
