import React from "react";
import { Box, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { SessionCalendarEvent } from "../../services/types/sessionDetailTypes";
import CclUnrestrictedButton from "../../components/common/cclButtons/cclUnrestrictedButton";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import { useGetAppUrlsQuery } from "../../services/cclTokenedSettingsApi";
import { useNavigate } from "react-router-dom";

export type CalendarSessionDetailDrawerProps = {
    open: boolean;
    evt: SessionCalendarEvent | null;
    onClose: () => void;
};

const CalendarSessionDetailDrawer: React.FC<CalendarSessionDetailDrawerProps> = (props) => {
    const { data: urls } = useGetAppUrlsQuery();
    const navigate = useNavigate();

    function getCellSx(isLabel: boolean) {
        return isLabel
            ? {
                  fontSize: 14,
                  borderBottom: "transparent",
                  width: "fi",
              }
            : {
                  fontWeight: "bold",
                  fontSize: 14,
                  borderBottom: "transparent",
                  padding: "3px",
              };
    }

    function getFormattedDateForCell(dt: Date | undefined | null) {
        return dt != null
            ? new Date(dt).toLocaleString("default", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
              })
            : "-";
    }

    const handleClose = () => {
        props.onClose();
    };

    const handleScore = () => {
        window.open(`${urls["CCLScoring"]}/program-status/${props.evt?.programId}`, "_blank");
    };

    const handleViewSession = () => {
        navigate(`/session/${props.evt?.programId}`);
    };

    return (
        <CclDrawer open={props.open} onClose={handleClose} title={"Session Detail"}>
            <Stack
                direction={"row"}
                spacing={3}
                sx={{
                    paddingLeft: "24px",
                }}
            >
                <Typography
                    align={"left"}
                    sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        paddingTop: "4px",
                    }}
                >
                    {props.evt?.title ?? "Error"}
                </Typography>
            </Stack>
            <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>ID:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {props.evt?.programId ?? "-"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>Code:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {props.evt?.programCode ?? "-"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>Score Date:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {getFormattedDateForCell(props.evt?.scoreDate)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>Start Date:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {getFormattedDateForCell(props.evt?.startDate)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>End Date:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {getFormattedDateForCell(props.evt?.endDate)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={getCellSx(true)}>Campus:</TableCell>
                            <TableCell sx={getCellSx(false)}>
                                {props.evt?.campusAbbreviation}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ pl: 5 }}>
                    <CclUnrestrictedButton onClick={handleScore} sx={{ mr: 1, ml: 1 }}>
                        Score Assessments
                    </CclUnrestrictedButton>
                    <CclUnrestrictedButton onClick={handleViewSession} sx={{ ml: 3 }}>
                        View Session
                    </CclUnrestrictedButton>
                </Box>
                <Box sx={{ pr: 5 }}>
                    <CclUnrestrictedButton onClick={handleClose} sx={{ mr: 1, float: "right" }}>
                        Close
                    </CclUnrestrictedButton>
                </Box>
            </Box>
        </CclDrawer>
    );
};

export default CalendarSessionDetailDrawer;
